.modal.show .modal-dialog.pop-up {
  margin: 0px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  transition: none !important;
}
@media (min-width: 576px) {
  .modal.show .modal-dialog.pop-up {
    max-width: 300px !important;
  }
}

.layout--video {
  overflow: hidden;
  position: relative;
  /**
  /* mods
  **/
}
.layout--video .layout--video__video-container {
  /*position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;*/
}
.layout--video .layout--video__video-container video {
  min-width: 100%;
  min-height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  clip-path: inset(1px 1px);
}
.layout--video .layout--video__video-container-general-pages video {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  clip-path: inset(1px 1px);
}
.layout--video > .container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  height: 100%;
  text-align: center;
  overflow: hidden;
}
.layout--video.layout--video--CEX h1 {
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: motiva-sans;
}
.layout--video.layout--video--FMC h1 {
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: motiva-sans;
}
.layout--video.layout--video--ABE h1 {
  font-size: 45px;
  font-weight: 700;
  line-height: 50px;
  font-family: azo-sans-web;
}

.layout--colour-none {
  background-color: unset;
}
.layout--colour-cex-light-blue {
  background-color: #d7d7e6;
}
.layout--colour-cex-light-blue-two {
  background-color: #2d55a6;
}
.layout--colour-cex-dark-blue {
  background-color: #233166;
}
.layout--colour-sat-light-blue {
  background-color: #dbddf1;
}
.layout--colour-ocs-light-blue {
  background-color: #d9dfe5;
}
.layout--colour-spc-light-blue {
  background-color: #e2e8f1;
}
.layout--colour-bre-light-blue {
  background-color: #e2e8f1;
}
.layout--colour-fmx-black {
  background-color: #1a1411;
}
.layout--colour-black {
  background-color: black;
}
.layout--colour-hpp-light-blue {
  background-color: #dae2e6;
}
.layout--colour-fmx-light-blue {
  background-color: #d3dce0;
}
.layout--colour-fmx-light-grey {
  background-color: #e4e3e3;
}
.layout--colour-abx-blue {
  background-color: #348ac0;
}
.layout--colour-abx-light-blue {
  background-color: #e3ecf7;
}
.layout--colour-spc-black {
  background-color: #000000;
}
.layout--colour-spc-grey {
  background-color: #ddddde;
}
.layout--colour-nzb-black {
  background-color: #313f42;
}
.layout--colour-nzb-light-green {
  background-color: #e0ebeb;
}
.layout--colour-fme {
  background-color: #fce6de;
}
.layout--colour-cex-gold {
  background-color: #b18b2f;
}
.layout--colour-tee-blue {
  background-color: #0f52ab;
}
.layout--colour-tme-light-red {
  background-color: #e3c7c1;
}
.layout--colour-tee-light-grey {
  background-color: #C0C0C0;
}
.layout--colour-tee-dark-grey {
  background-color: #333333;
}
.layout--colour-abx-valentines-pink {
  background-color: #fa759c;
}
.layout--colour-fmx-valentines-grey {
  background-color: #232423;
}
.layout--colour-abm-green {
  background-color: #006b4c;
}
.layout--colour-abm-orange {
  background-color: #df5c43;
}
.layout--colour-fmx-pink {
  background-color: #d52d60;
}
.layout--colour-fmx-brandblue {
  background-color: #518a94;
}
.layout--colour-fmx-darkblue {
  background-color: #2c4c51;
}
.layout--colour-fmx-grey {
  background-color: #575656;
}
.layout--colour-abx-brandblue {
  background-color: #348ac0;
}
.layout--colour-abx-darkblue {
  background-color: #144f74;
}
.layout--colour-abx-yellow {
  background-color: #c08531;
}

.layout--image-inner-hero {
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 200px;
}
.layout--image-inner-hero > .container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  text-align: center;
  overflow: hidden;
}
.layout--image-inner-hero .layout--image-inner-hero__text-container {
  width: fit-content;
}
.layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  padding-right: 10px;
  padding-left: 10px;
}
.layout--image-inner-hero .layout--image-inner-hero__text-container h1 {
  color: #fff;
}

[data-context^=OCS] .layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  background-color: rgba(52, 117, 135, 0.85);
}

[data-context^=SAT] .layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  background-color: rgba(49, 68, 123, 0.85);
}

[data-context^=CEX] .layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  background-color: rgba(44, 51, 100, 0.85);
}
[data-context^=CEX] .layout--image-inner-hero .layout--image-inner-hero__text-container h1 {
  margin-bottom: 0;
  padding-top: 10px;
  font-size: 45px;
}

[data-context^=FMC] .layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  background-color: rgba(227, 4, 39, 0.85);
}
[data-context^=FMC] .layout--image-inner-hero .layout--image-inner-hero__text-container h1 {
  margin-bottom: 0;
  font-size: 45px;
}

[data-context^=FMX] .layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  background-color: rgba(81, 138, 148, 0.85);
}
[data-context^=FMX] .layout--image-inner-hero .layout--image-inner-hero__text-container h1 {
  margin-bottom: 0;
  font-size: 45px;
}

[data-context^=ABX] .layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  background-color: rgba(52, 138, 192, 0.85);
}
[data-context^=ABX] .layout--image-inner-hero .layout--image-inner-hero__text-container h1 {
  margin-bottom: 0;
  font-size: 45px;
}

[data-context^=NZB] .layout--image-inner-hero .layout--image-inner-hero__text-container h1 {
  margin-bottom: 0;
  font-size: 45px;
}

[data-context^=TTE] .layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  background-color: rgba(173, 45, 32, 0.85);
}
[data-context^=TTE] .layout--image-inner-hero .layout--image-inner-hero__text-container h1 {
  margin-bottom: 0;
  font-size: 45px;
}

[data-context^=FME] .layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  background-color: rgba(237, 104, 57, 0.85);
}
[data-context^=FME] .layout--image-inner-hero .layout--image-inner-hero__text-container h1 {
  margin-bottom: 0;
  font-size: 45px;
}

[data-context^=BRE] .layout--image-inner-hero .layout--image-inner-hero__text-container > * {
  background-color: rgba(12, 51, 83, 0.85);
}
[data-context^=BRE] .layout--image-inner-hero .layout--image-inner-hero__text-container h1 {
  margin-bottom: 0;
  font-size: 45px;
}

.layout--image {
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
}
.layout--image > .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  height: 100%;
  overflow: hidden;
}
.layout--image.layout--image--hero h1,
.layout--image.layout--image--hero p {
  color: #fff;
}
.layout--image.layout--image--hero h1 {
  font-size: 74px;
  font-weight: bold;
}
.layout--image.layout--image--hero p {
  font-size: 40px;
}
.layout--image.layout--image--repeat {
  background-repeat: repeat;
  height: auto !important;
}

.layout-map-footer {
  height: 600px;
}
.layout-map-footer .layout-map-footer__right {
  padding-right: 0;
}
@media (max-width: 768px) {
  .layout-map-footer .layout-map-footer__right {
    padding-left: 0;
  }
}
.layout-map-footer .layout-map-footer__right iframe {
  width: 100%;
}
@media (max-width: 768px) {
  .layout-map-footer .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

.layout-full {
  position: relative;
  width: auto;
}

@media (max-width: 768px) {
  .layout-full--ignore-custom-height {
    height: unset !important;
  }
}

.layout-single {
  position: relative;
}

.layout-side-right {
  position: relative;
}

.layout-side-left {
  position: relative;
}

.layout-tri-col {
  position: relative;
}

.layout-six-col,
.layout-five-col {
  position: relative;
}

.layout-quad-col {
  position: relative;
}

._layout-double-col {
  position: relative;
}

@media (max-width: 768px) {
  .layout--hide-mob {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .layout--hide-desk {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .layout-col--borders .row > div + div {
    border-left: 1px solid black;
  }
}

.curved-cta {
  display: block;
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px 3px 10px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  border: none;
  color: #fff;
  transition: background 0.2s linear !important;
  -webkit-transition: background 0.2s linear;
}
.curved-cta--hard {
  border-radius: 0;
}
.curved-cta.left {
  margin-left: 0;
}
.curved-cta.right {
  margin-right: 0;
}
.curved-cta:focus {
  border: none;
  outline: none;
  box-shadow: none;
  color: #fff;
}
.curved-cta .curved-cta__link {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
}
.curved-cta .curved-cta__link:hover {
  text-decoration: none;
}
.curved-cta .curved-cta__link:focus {
  color: #fff;
  text-decoration: none;
}
.curved-cta--ocs-blue {
  background-color: #347587;
}
.curved-cta--ocs-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #347587;
}
.curved-cta--ocs-blue:hover a,
.curved-cta--ocs-blue:hover span {
  color: #347587 !important;
}
.curved-cta--sat-blue {
  background-color: #4564ad;
}
.curved-cta--sat-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #4564ad;
}
.curved-cta--sat-blue:hover a,
.curved-cta--sat-blue:hover span {
  color: #4564ad !important;
}
.curved-cta--sat-dark-blue {
  background-color: #31447b;
}
.curved-cta--sat-dark-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #31447b;
}
.curved-cta--sat-dark-blue:hover a,
.curved-cta--sat-dark-blue:hover span {
  color: #31447b !important;
}
.curved-cta--fmc {
  background-color: #e30427;
}
.curved-cta--fmc:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #e30427;
}
.curved-cta--fmc:hover a,
.curved-cta--fmc:hover span {
  color: #e30427 !important;
}
.curved-cta--cex-gold {
  background-color: #b18b2f;
}
.curved-cta--cex-gold:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #b18b2f;
}
.curved-cta--cex-gold:hover a,
.curved-cta--cex-gold:hover span {
  color: #b18b2f !important;
}
.curved-cta--cex-purple {
  background-color: #2c3364;
}
.curved-cta--cex-purple:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #2c3364;
}
.curved-cta--cex-purple:hover a,
.curved-cta--cex-purple:hover span {
  color: #2c3364 !important;
}
.curved-cta--cex-red {
  background-color: #b62f25;
}
.curved-cta--cex-red:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #b62f25;
}
.curved-cta--cex-red:hover a,
.curved-cta--cex-red:hover span {
  color: #b62f25 !important;
}
.curved-cta--black {
  background-color: black;
}
.curved-cta--black:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px black;
}
.curved-cta--black:hover a,
.curved-cta--black:hover span {
  color: black !important;
}
.curved-cta--abe-yellow {
  background-color: #cd842c;
}
.curved-cta--abe-yellow:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #cd842c;
}
.curved-cta--abe-yellow:hover a,
.curved-cta--abe-yellow:hover span {
  color: #cd842c !important;
}
.curved-cta--abe-light-blue {
  background-color: #7b87c2;
}
.curved-cta--abe-light-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #7b87c2;
}
.curved-cta--abe-light-blue:hover a,
.curved-cta--abe-light-blue:hover span {
  color: #7b87c2 !important;
}
.curved-cta--abe-dark-blue {
  background-color: #26358c;
}
.curved-cta--abe-dark-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #26358c;
}
.curved-cta--abe-dark-blue:hover a,
.curved-cta--abe-dark-blue:hover span {
  color: #26358c !important;
}
.curved-cta--hpp-orange {
  background-color: #e7624f;
}
.curved-cta--hpp-orange:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #e7624f;
}
.curved-cta--hpp-orange:hover a,
.curved-cta--hpp-orange:hover span {
  color: #e7624f !important;
}
.curved-cta--hpp-blue {
  background-color: #0d4459;
}
.curved-cta--hpp-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #0d4459;
}
.curved-cta--hpp-blue:hover a,
.curved-cta--hpp-blue:hover span {
  color: #0d4459 !important;
}
.curved-cta--tte-red {
  background-color: #ad2d20;
}
.curved-cta--tte-red:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #ad2d20;
}
.curved-cta--tte-red:hover a,
.curved-cta--tte-red:hover span {
  color: #ad2d20 !important;
}
.curved-cta--fmx-blue {
  background-color: #4d8691;
}
.curved-cta--fmx-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #4d8691;
}
.curved-cta--fmx-blue:hover a,
.curved-cta--fmx-blue:hover span {
  color: #4d8691 !important;
}
.curved-cta--fme-orange {
  background-color: #ed6839;
}
.curved-cta--fme-orange:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #ed6839;
}
.curved-cta--fme-orange:hover a,
.curved-cta--fme-orange:hover span {
  color: #ed6839 !important;
}
.curved-cta--abx-blue {
  background-color: #348ac0;
}
.curved-cta--abx-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #348ac0;
}
.curved-cta--abx-blue:hover a,
.curved-cta--abx-blue:hover span {
  color: #348ac0 !important;
}
.curved-cta--spc-blue {
  background-color: #167c9f;
}
.curved-cta--spc-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #167c9f;
}
.curved-cta--spc-blue:hover a,
.curved-cta--spc-blue:hover span {
  color: #167c9f !important;
}
.curved-cta--bre-blue {
  background-color: #0c3353;
}
.curved-cta--bre-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #0c3353;
}
.curved-cta--bre-blue:hover a,
.curved-cta--bre-blue:hover span {
  color: #0c3353 !important;
}
.curved-cta--spc-orange {
  background-color: #bf1427;
}
.curved-cta--spc-orange:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #bf1427;
}
.curved-cta--spc-orange:hover a,
.curved-cta--spc-orange:hover span {
  color: #bf1427 !important;
}
.curved-cta--nzb-green {
  background-color: #12a64f;
}
.curved-cta--nzb-green:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #12a64f;
}
.curved-cta--nzb-green:hover a,
.curved-cta--nzb-green:hover span {
  color: #12a64f !important;
}
.curved-cta--bre-yellow {
  background-color: #f09d00;
}
.curved-cta--bre-yellow:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #f09d00;
}
.curved-cta--bre-yellow:hover a,
.curved-cta--bre-yellow:hover span {
  color: #f09d00 !important;
}
.curved-cta--tee-blue {
  background-color: #0f52ab;
}
.curved-cta--tee-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #0f52ab;
}
.curved-cta--tee-blue:hover a,
.curved-cta--tee-blue:hover span {
  color: #0f52ab !important;
}
.curved-cta--solutions-blue {
  background-color: #134f73;
}
.curved-cta--solutions-blue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #134f73;
}
.curved-cta--solutions-blue:hover a,
.curved-cta--solutions-blue:hover span {
  color: #134f73 !important;
}
.curved-cta--SmartFoamMachinery-orange {
  background-color: #e97f37;
}
.curved-cta--SmartFoamMachinery-orange:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #e97f37;
}
.curved-cta--SmartFoamMachinery-orange:hover a,
.curved-cta--SmartFoamMachinery-orange:hover span {
  color: #e97f37 !important;
}
.curved-cta--solutions-blue-FME-ABE-TEE {
  background-color: #305dab;
}
.curved-cta--solutions-blue-FME-ABE-TEE:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #305dab;
}
.curved-cta--solutions-blue-FME-ABE-TEE:hover a,
.curved-cta--solutions-blue-FME-ABE-TEE:hover span {
  color: #305dab !important;
}
.curved-cta--abm-orange {
  background-color: #DF5C43;
}
.curved-cta--abm-orange:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #DF5C43;
}
.curved-cta--abm-orange:hover a,
.curved-cta--abm-orange:hover span {
  color: #DF5C43 !important;
}
.curved-cta--abm-green {
  background-color: #1D6C4C;
}
.curved-cta--abm-green:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #1D6C4C;
}
.curved-cta--abm-green:hover a,
.curved-cta--abm-green:hover span {
  color: #1D6C4C !important;
}
.curved-cta--abx-valentines-pink {
  background-color: #fa759c;
}
.curved-cta--abx-valentines-pink:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #fa759c;
}
.curved-cta--abx-valentines-pink:hover a,
.curved-cta--abx-valentines-pink:hover span {
  color: #fa759c !important;
}
.curved-cta--fmx-valentines-grey {
  background-color: #232423;
}
.curved-cta--fmx-valentines-grey:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #232423;
}
.curved-cta--fmx-valentines-grey:hover a,
.curved-cta--fmx-valentines-grey:hover span {
  color: #232423 !important;
}
.curved-cta--fmx-pink {
  background-color: #d52d60;
}
.curved-cta--fmx-pink:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #d52d60;
}
.curved-cta--fmx-pink:hover a,
.curved-cta--fmx-pink:hover span {
  color: #d52d60 !important;
}
.curved-cta--fmx-brandblue {
  background-color: #518a94;
}
.curved-cta--fmx-brandblue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #518a94;
}
.curved-cta--fmx-brandblue:hover a,
.curved-cta--fmx-brandblue:hover span {
  color: #518a94 !important;
}
.curved-cta--fmx-darkblue {
  background-color: #2c4c51;
}
.curved-cta--fmx-darkblue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #2c4c51;
}
.curved-cta--fmx-darkblue:hover a,
.curved-cta--fmx-darkblue:hover span {
  color: #2c4c51 !important;
}
.curved-cta--fmx-grey {
  background-color: #575656;
}
.curved-cta--fmx-grey:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #575656;
}
.curved-cta--fmx-grey:hover a,
.curved-cta--fmx-grey:hover span {
  color: #575656 !important;
}
.curved-cta--abx-brandblue {
  background-color: #348ac0;
}
.curved-cta--abx-brandblue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #348ac0;
}
.curved-cta--abx-brandblue:hover a,
.curved-cta--abx-brandblue:hover span {
  color: #348ac0 !important;
}
.curved-cta--abx-darkblue {
  background-color: #144f74;
}
.curved-cta--abx-darkblue:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #144f74;
}
.curved-cta--abx-darkblue:hover a,
.curved-cta--abx-darkblue:hover span {
  color: #144f74 !important;
}
.curved-cta--abx-yellow {
  background-color: #c08531;
}
.curved-cta--abx-yellow:hover {
  background: #fff;
  box-shadow: inset 0 0 0px 1.5px #c08531;
}
.curved-cta--abx-yellow:hover a,
.curved-cta--abx-yellow:hover span {
  color: #c08531 !important;
}
.curved-cta--fmc {
  box-shadow: inset 0 0 0px 1.5px #fff;
}
.curved-cta--max {
  margin: auto;
  width: 95%;
}
.curved-cta--max a {
  font-size: 26px;
}
.curved-cta--micro a {
  font-size: 1rem;
  font-weight: 400;
}

body[data-context=FMX-PRTL] .layout-side-right .col-xs-12.col-sm-12.col-md-4 .curved-cta--reg,
body[data-context=SPC-PRTL] .layout-side-right .col-xs-12.col-sm-12.col-md-4 .curved-cta--reg {
  width: 100%;
}

body[data-context=CEX] .curved-cta {
  padding: 3px 10px 2px 10px;
}

body[data-context=FMC] .curved-cta {
  padding: 3px 10px;
}

button.curved-cta.layout--colour-abx-valentines-pink.curved-cta--reg {
  border: 2px solid white;
}
button.curved-cta.layout--colour-abx-valentines-pink.curved-cta--reg:hover {
  opacity: 0.8;
}

button.curved-cta.layout--colour-fmx-valentines-grey.curved-cta--reg {
  border: 2px solid white;
}
button.curved-cta.layout--colour-fmx-valentines-grey.curved-cta--reg:hover {
  opacity: 0.8;
}

.image--center {
  text-align: center;
}
.image--left {
  text-align: left;
}
.image--right {
  text-align: right;
}
.image__text-hover img:hover {
  opacity: 0.5;
  transition: opacity 0.2s linear;
  z-index: 2;
}
.image__text-hover p {
  margin: 0;
  width: 100%;
  z-index: -1;
  text-align: center;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 45%;
  font-weight: bold;
}

.spacer {
  visibility: hidden;
}
.spacer--disable-top {
  margin-top: 0 !important;
}

.rich-text p:last-child {
  margin-bottom: 0;
}

.lead-capture {
  text-align: left;
  padding: 12px;
  position: relative;
}
.lead-capture .form-control:focus {
  box-shadow: inset 0 0 0 0.1rem;
}
.lead-capture--white-font p,
.lead-capture--white-font .lead-capture__spinner,
.lead-capture--white-font .lead-capture__thankyou,
.lead-capture--white-font .lead-capture__thankyou {
  color: #fff;
}
.lead-capture--white-font .lead-capture__disclaimer {
  color: #fff;
}
.lead-capture--white-font .lead-capture__disclaimer a {
  color: #fff;
}
.lead-capture--white-font .lead-capture__disclaimer:hover {
  color: #fff;
}
.lead-capture .curved-cta {
  margin-left: 0;
}
.lead-capture .lead-capture__spinner,
.lead-capture .lead-capture__thankyou {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  align-items: center;
  color: black;
}
.lead-capture .lead-capture__spinner .spinner-border,
.lead-capture .lead-capture__thankyou .spinner-border {
  width: 5rem;
  height: 5rem;
}
.lead-capture.lead-capture--align-left {
  margin: auto;
  margin-left: 0;
}
.lead-capture.lead-capture--align-center {
  margin: auto;
}
.lead-capture.lead-capture--align-right {
  margin: auto;
  margin-right: 0;
}
.lead-capture.lead-capture--no-padding {
  padding: 0px;
}

.accordian-customisable .accordion-item .session__title .accordion-button {
  font-size: inherit;
  font-weight: bold;
  color: unset;
  box-shadow: none;
}
.accordian-customisable .accordion-item .session__title .accordion-button:focus {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordian-customisable .accordion-item .session__title .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordian-customisable .accordion-item .accordion-collapse .accordion-body {
  padding: 0.5rem 1.25rem;
}
.accordian-customisable .accordion-item .accordion-collapse .accordion-body > * {
  padding: 0;
  margin: 0;
}
.accordian-customisable.accordian-customisable--CEX .accordion-button {
  background-color: #d7d7e6;
}
.accordian-customisable .accordian-customisable--FMX .accordion-button {
  background-color: #d3dce0;
}
.accordian-customisable .accordian-customisable--FME .accordion-button {
  background-color: #ed6839;
}
.accordian-customisable .accordian-customisable--ABX .accordion-button {
  background-color: #348ac0;
}

.on-demand-content .on-demand-content__nav {
  padding: 15px 0;
  padding-bottom: 0;
}
.on-demand-content .on-demand-content__nav > p:first-child {
  font-weight: bold;
  color: black;
}
.on-demand-content .on-demand-content__nav > p:last-child {
  padding: 20px 0px;
  margin-bottom: 0;
}
.on-demand-content .on-demand-content__nav .nav-items {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.on-demand-content .on-demand-content__nav .nav-items .on-demand-nav-item {
  cursor: pointer;
  margin: 0 15px;
  margin-bottom: 0;
  padding: 10px 0;
}
.on-demand-content .on-demand-content__nav .nav-items .on-demand-nav-item.active {
  color: black;
  font-weight: bold;
}
.on-demand-content .on-demand-day-divider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid black;
}
.on-demand-content .on-demand-day-divider h2,
.on-demand-content .on-demand-day-divider p {
  height: fit-content;
  margin-bottom: 0;
  line-height: 1;
}
.on-demand-content .on-demand-day-divider .on-demand-day-divider__btt {
  font-weight: bold;
  font-style: italic;
  margin-right: 15px;
  cursor: pointer;
  color: black;
}
.on-demand-content .on-demand-day-divider .on-demand-day-divider__btt:hover {
  color: #404040;
  text-decoration: underline;
}
.on-demand-content .on-demand-item {
  padding: 20px 0px;
}
.on-demand-content .on-demand-item h5 {
  font-weight: bold;
}
@media (max-width: 768px) {
  .on-demand-content .on-demand-item h5 {
    margin-top: 20px;
  }
}
.on-demand-content .on-demand-item .on-demand-iframe {
  text-align: center;
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .on-demand-content .on-demand-item .on-demand-iframe {
    margin-top: -5px;
  }
}
.on-demand-content .on-demand-item .on-demand-iframe iframe {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 215px;
}

.cb-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.cb-video iframe,
.cb-video object,
.cb-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cb-video--w-100 {
  width: 100%;
}
.cb-video--w-50 {
  width: 50%;
}
.cb-video--w-25 {
  width: 25%;
}
.cb-video--w-75 {
  width: 75%;
}
.cb-video--w-33 {
  width: 33%;
}
.cb-video--w-66 {
  width: 66%;
}
.cb-video--center {
  margin: auto;
}
.cb-video--left {
  margin-left: 0;
}
.cb-video--right {
  margin-right: 0;
}

.ss-countdown__days, .ss-countdown__hours, .ss-countdown__minutes, .ss-countdown__seconds {
  text-align: center;
}
.ss-countdown__days p, .ss-countdown__hours p, .ss-countdown__minutes p, .ss-countdown__seconds p {
  text-transform: uppercase;
}

.ss-countdown--nzb-dark h1 {
  color: #0ea64f;
}
.ss-countdown--nzb-light h1 {
  color: #0ea64f;
}
.ss-countdown--nzb-light p {
  font-weight: bold;
  color: #fff;
}

.vertical-margin--0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.vertical-margin--5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.vertical-margin--10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.vertical-margin--20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.vertical-margin--30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.vertical-margin--40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.vertical-margin--50 {
  margin-top: 50px;
  margin-bottom: 50px;
}