.layout--image {
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
    background-size:cover;
	& > .container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		height: 100%;
		overflow: hidden;
	}
	&.layout--image--hero {
		h1,
		p {
			color: #fff;
		}
		h1 {
			font-size: 74px;
			font-weight: bold;
		}
		p {
			font-size: 40px;
		}
	}
	&.layout--image--repeat {
		background-repeat: repeat;
		height: auto !important;
	}
}
