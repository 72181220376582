.layout--colour- {
    &none {
        background-color: unset;
    }
    $colors: ("cex-light-blue", #d7d7e6),("cex-light-blue-two", #2d55a6),("cex-dark-blue", #233166), ("sat-light-blue", #dbddf1), ("ocs-light-blue", #d9dfe5), ("spc-light-blue", #e2e8f1), ("bre-light-blue", #e2e8f1),
        ("fmx-black", #1a1411), ("black", black), ("hpp-light-blue", #dae2e6), ("fmx-light-blue", #d3dce0), ("fmx-light-grey", #e4e3e3), ("abx-blue", #348ac0),
        ("abx-light-blue", #e3ecf7), ("spc-black", #000000), ("spc-grey", #ddddde), ("nzb-black", #313f42), ("nzb-light-green", #e0ebeb), ("fme", #fce6de),
        ("cex-gold", #b18b2f),("tee-blue", #0f52ab), ("tme-light-red", #e3c7c1),("tee-light-grey", #C0C0C0),("tee-dark-grey", #333333),("abx-valentines-pink", #fa759c),
        ("fmx-valentines-grey", #232423),("abm-green", #006b4c),("abm-orange", #df5c43),("fmx-pink", #d52d60),("fmx-brandblue", #518a94),("fmx-darkblue", #2c4c51),("fmx-grey", #575656),("abx-brandblue", #348ac0), 
        ("abx-darkblue", #144f74), ("abx-yellow", #c08531)  ;
    @each $color in $colors {
        &#{nth($color, 1)} {
            background-color: nth($color, 2);
        }
    }
    
   
}
