$mobile-width: 768px;
//popup
    .modal.show .modal-dialog.pop-up {
        @media (min-width: 576px){
            max-width: 300px!important;
        }
    margin:0px!important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    transition:none!important;

}
@import "./layouts/layouts", "./fields/fields", "./utils";
