.layout--video {
	overflow: hidden;
	position: relative;
	.layout--video__video-container {
		//Zooms in for some reason (seems unneccesary anyway)
		/*position: absolute;
		width: 100vw;
		height: 100vh;
		left: 0;*/
		video {
			min-width: 100%;
			min-height: 100%;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			clip-path: inset(1px 1px);
		}
	}
	.layout--video__video-container-general-pages{
	    	video {
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
				clip-path: inset(1px 1px);
		}
	}
	& > .container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		height: 100%;
		text-align: center;
		overflow: hidden;
	}
	/**
	/* mods
	**/
	&.layout--video--CEX {
		h1 {
			font-size: 45px;
			font-weight: 700;
			line-height: 50px;
			font-family: motiva-sans;
		}
	}
	&.layout--video--FMC {
		h1 {
			font-size: 45px;
			font-weight: 700;
			line-height: 50px;
			font-family: motiva-sans;
		}
	}
	&.layout--video--ABE {
		h1 {
			font-size: 45px;
			font-weight: 700;
			line-height: 50px;
			font-family: azo-sans-web;
		}
	}
}
