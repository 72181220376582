.layout--hide-mob {
	@media (max-width: $mobile-width) {
		display: none!important;
	}
}
.layout--hide-desk {
	@media (min-width: $mobile-width) {
		display: none!important;
	}
}
.layout-col--borders {
	@media (min-width: $mobile-width) {
		.row > div + div {
			border-left: 1px solid black;
		}
	}
}
