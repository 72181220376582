.accordian-customisable {
	.accordion-item {
		.session__title {
			.accordion-button {
				font-size: inherit;
				font-weight: bold;
				color: unset;
				box-shadow: none;
				&:focus {
					border: 1px solid rgba(0, 0, 0, 0.125);
					//border-color: inherit;
				}
				&::after {
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
				}
			}
		}
		.accordion-collapse {
			.accordion-body {
				padding: 0.5rem 1.25rem;
				& > * {
					padding: 0;
					margin: 0;
				}
			}
		}
	}
	&.accordian-customisable--CEX {
		.accordion-button {
			background-color: #d7d7e6;
		}
	}
	.accordian-customisable--FMX {
		.accordion-button {
			background-color: #d3dce0;
		}
	}
	.accordian-customisable--FME {
		.accordion-button {
			background-color: #ed6839;
		}
	}
	.accordian-customisable--ABX {
		.accordion-button {
			background-color: #348ac0;
		}
	}
}
