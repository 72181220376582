.cb-video {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&--w- {
		&100 {
			width: 100%;
		}
		&50 {
			width: 50%;
		}
		&25 {
			width: 25%;
		}
		&75 {
			width: 75%;
		}
		&33 {
			width: 33%;
		}
		&66 {
			width: 66%;
		}
	}
	&--center {
		margin: auto;
	}
	&--left {
		margin-left: 0;
	}
	&--right {
		margin-right: 0;
	}
}
