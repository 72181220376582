.layout--image-inner-hero {
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	height: 200px;
	& > .container {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		height: 100%;
		text-align: center;
		overflow: hidden;
	}
	.layout--image-inner-hero__text-container {
		width: fit-content;
		& > * {
			padding-right: 10px;
			padding-left: 10px;
		}
		h1 {
			color: #fff;
		}
	}
}
[data-context^="OCS"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				background-color: rgba(52, 117, 135, 0.85);
			}
		}
	}
}
[data-context^="SAT"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				background-color: rgba(49, 68, 123, 0.85);
			}
		}
	}
}
[data-context^="CEX"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				background-color: rgba(44, 51, 100, 0.85);
			}
			h1 {
				margin-bottom: 0;
				padding-top: 10px;
				font-size: 45px;
			}
		}
	}
}
[data-context^="FMC"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				background-color: rgba(227, 4, 39, 0.85);
			}
			h1 {
				margin-bottom: 0;
				//padding-top: 10px;
				font-size: 45px;
			}
		}
	}
}
[data-context^="FMX"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				background-color: rgba(81, 138, 148, 0.85);
			}
			h1 {
				margin-bottom: 0;
				//padding-top: 10px;
				font-size: 45px;
			}
		}
	}
}
[data-context^="ABX"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				background-color: rgba(52, 138, 192, 0.85);
			}
			h1 {
				margin-bottom: 0;
				//padding-top: 10px;
				font-size: 45px;
			}
		}
	}
}
[data-context^="NZB"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				//background-color: rgba(18,166,79, 0.85);
			}
			h1 {
				margin-bottom: 0;
				//padding-top: 10px;
				font-size: 45px;
			}
		}
	}
}
[data-context^="TTE"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				background-color: rgba(173, 45, 32, 0.85);
			}
			h1 {
				margin-bottom: 0;
				//padding-top: 10px;
				font-size: 45px;
			}
		}
	}
}
[data-context^="FME"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				background-color: rgba(237, 104, 57, 0.85);
			}
			h1 {
				margin-bottom: 0;
				//padding-top: 10px;
				font-size: 45px;
			}
		}
	}
}
[data-context^="BRE"] {
	.layout--image-inner-hero {
		.layout--image-inner-hero__text-container {
			& > * {
				background-color: rgba(12,51,83, 0.85);
			}
			h1 {
				margin-bottom: 0;
				//padding-top: 10px;
				font-size: 45px;
			}
		}
	}
}
