.lead-capture {
	text-align: left;
     padding: 12px;
	position: relative;
	.form-control {
		&:focus {
			box-shadow: inset 0 0 0 0.1rem;
		}
	}
	&--white-font {
		p,
		.lead-capture__spinner,
		.lead-capture__thankyou,
		.lead-capture__thankyou {
			color: #fff;
		}
		.lead-capture__disclaimer {
			color: #fff;
			a {
				color: #fff;
			}
			&:hover {
				color: #fff;
			}
		}
	}
	.curved-cta {
		margin-left: 0;
	}
	.lead-capture__spinner,
	.lead-capture__thankyou {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		align-items: center;
		color: black;
		.spinner-border {
			width: 5rem;
			height: 5rem;
		}
	}
	.lead-capture__thankyou {
		//color: unset;
	}
	&.lead-capture--align-left {
		margin: auto;
		margin-left: 0;
	}
	&.lead-capture--align-center {
		margin: auto;
	}
	&.lead-capture--align-right {
		margin: auto;
		margin-right: 0;
	}
	&.lead-capture--no-padding {
		padding: 0px;
	}
}
