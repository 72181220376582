.layout-map-footer {
	height: 600px;
	.layout-map-footer__right {
		padding-right: 0;
		@media (max-width: $mobile-width) {
			padding-left: 0;
		}
		iframe {
			width: 100%;
		}
	}
	@media (max-width: $mobile-width) {
		.container-fluid {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
