.curved-cta {
    $colors: ("ocs-blue", #347587), ("sat-blue", #4564ad), ("sat-dark-blue", #31447b), ("fmc", #e30427), ("cex-gold", #b18b2f), ("cex-purple", #2c3364),
        ("cex-red", #b62f25), ("black", black), ("abe-yellow", #cd842c), ("abe-light-blue", #7b87c2), ("abe-dark-blue", #26358c), ("hpp-orange", #e7624f),
        ("hpp-blue", #0d4459), ("tte-red", #ad2d20), ("fmx-blue", #4d8691), ("fme-orange", #ed6839), ("abx-blue", #348ac0), ("spc-blue", #167c9f),
        ("bre-blue", #0c3353), ("spc-orange", #bf1427), ("nzb-green", #12a64f), ("bre-yellow", #f09d00),("tee-blue", #0f52ab),("solutions-blue", #134f73),
        ("SmartFoamMachinery-orange", #e97f37),("solutions-blue-FME-ABE-TEE", #305dab),("abm-orange", #DF5C43),("abm-green", #1D6C4C),("abx-valentines-pink", #fa759c)
        ,("fmx-valentines-grey", #232423),("fmx-pink", #d52d60),("fmx-brandblue", #518a94),("fmx-darkblue", #2c4c51),("fmx-grey", #575656),("abx-brandblue", #348ac0), 
        ("abx-darkblue", #144f74), ("abx-yellow", #c08531)  ;
        





  
        
    display: block;
    border-radius: 10px;
    text-align: center;
    padding: 5px 10px 3px 10px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    border: none;
    color: #fff;
    transition: background 0.2s linear !important;
    -webkit-transition: background 0.2s linear;
    &--hard {
        border-radius: 0;
    }
    &.center {
    }
    &.left {
        margin-left: 0;
    }
    &.right {
        margin-right: 0;
    }
    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
        color: #fff;
    }
    .curved-cta__link {
        color: #fff;
        font-weight: bold;
        font-size:14px;
        text-decoration: none;
        transition: background 0.2s linear;
        -webkit-transition: background 0.2s linear;
        &:hover {
            text-decoration: none;
        }
        &:focus {
            color: #fff;
            text-decoration: none;
        }
    }
    @each $color in $colors {
        &--#{nth($color, 1)} {
            background-color: nth($color, 2);
            &:hover {
                background: #fff;
                box-shadow: inset 0 0 0px 1.5px nth($color, 2);
                a,
                span {
                    color: nth($color, 2) !important;
                }
            }
           
        }
    } 
  
    &--fmc {
        box-shadow: inset 0 0 0px 1.5px #fff;
    }

    &--max {
        margin: auto;
        width: 95%;
        a {
            font-size: 26px;
        }
        
    }
    &--micro {
        a {
            font-size: 1rem;
            font-weight: 400;
        }
    }
}
//HACK FOR EXHIB PRTL
body[data-context="FMX-PRTL"],
body[data-context="SPC-PRTL"] {
    .layout-side-right {
        .col-xs-12.col-sm-12.col-md-4 {
            .curved-cta {
                &--reg {
                    width: 100%;
                }
            }
        }
    }
}

body[data-context="CEX"] {
    .curved-cta {
        padding: 3px 10px 2px 10px;
    }
}
body[data-context="FMC"] {
    .curved-cta {
        padding: 3px 10px;
    }
}

button.curved-cta.layout--colour-abx-valentines-pink.curved-cta--reg{
    border:2px solid white;
    &:hover{
        opacity :0.8;
        
    }
}
button.curved-cta.layout--colour-fmx-valentines-grey.curved-cta--reg{
    border:2px solid white;
    &:hover{
        opacity :0.8;
        
    }
}
